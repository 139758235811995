@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

@mixin homepageBrandListSprite() {
  background-image: url(/media/dental/common/homepage/sprite-dental-brands.jpg);
  &#brand- {
    &mydent-international {
      background-image: url(/media/dental/common/homepage/brands/DEFEND.svg);
      background-position: center center;
      background-size: 100px;
      background-repeat: no-repeat;
    }
    &metrex {
      background-image: url(/media/dental/common/homepage/brands/metrex-logo.svg);
      background-position: center center;
      background-size: 100px;
      background-repeat: no-repeat;
    }
    // {  }
    &premier-dental {
      background-image: url(/media/dental/common/homepage/brands/Premier_Logo_R_RGB.png);
      background-position: center center;
      background-size: 100px;
    }
    &bisco {
      background-position: center -176px;
    }
    &dmg-america {
      background-position: center -238px;
    }
    &sultan-healthcare {
      background-position: center -334px;
      background-size: 140px !important;
    }
    &septodont {
      background-position: center -422px;
    }
    &3m {
      background-image: url(/media/dental/common/homepage/brands/3M_logo_only.svg);
      background-position: center center;
      background-size: 65px;
    }
  }
}

#high-quality-brands {
  background-color: $vertical-color-90;
  display: grid;
  margin: auto;
  grid-template-columns: 280px auto;
  grid-template-rows: 160px;
  @include media-breakpoint-up(md) {
    max-width: 1300px;
    width: calc(100% - 120px);
  }

  @include media-breakpoint-down(lg) {
    grid-template-columns: 100%;
    grid-template-rows: 100px auto;
  }

  .hqb-title-container {
    @include media-breakpoint-up(lg) {
      padding: {
        right: 30px;
        left: 0.5rem;
      }
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    @include media-breakpoint-down(lg) {
      text-align: center;
      padding-top: 12px;
    }

    .hqb-title {
      font-size: 32px;
      font-size: 32px;
      line-height: 1.3em;
      font-weight: 500;
      @include media-breakpoint-up(lg) {
        padding: {
          bottom: 15px;
        }
      }
      .part-1 {
        display: inline-block;
      }
      .part-2 {
        display: inline-block;
        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }
  }
  .brand-list {
    .brand-icon {
      border: 5px solid $vertical-color-90;
      background-color: #ffffff;
      background-size: 150px;
      background-repeat: no-repeat;
      overflow: hidden;
      text-indent: -1000px;
      display: none; // overriden below by break points
      height: 80px;

      @include homepageBrandListSprite();

      // Desktop
      @include media-breakpoint-up(xl) {
        flex: 1 1 25%;

        &[data-show-on-desktop="true"] {
          display: block;
        }
        @for $i from 1 to 9 {
          &.order-desktop-#{$i} {
            order: #{$i};
          }
        }
      }
      // Tablet
      @include media-breakpoint-only(lg) {
        flex: 1 1 33%;

        &[data-show-on-tablet="true"] {
          display: block;
        }
        @for $i from 1 to 9 {
          &.order-tablet-#{$i} {
            order: #{$i};
          }
        }
      }
      @include media-breakpoint-down(lg) {
        flex: 1 1 50%;

        &[data-show-on-mobile="true"] {
          display: block;
        }
        @for $i from 1 to 9 {
          &.order-mobile-#{$i} {
            order: #{$i};
          }
        }
      }
    }
  }
}

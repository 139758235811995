@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.container {
  background-color: $vertical-color-42;
  width: 100%;
  background:
    linear-gradient(92deg, #7ba6efeb, #0b59e0eb 47.92%, #7ba6efeb),
    url(/media/shared/common/backgrounds/Search-Section-D.jpg) 50% / cover no-repeat #d3d3d3;
}

.homePageUserInfo {
  display: grid;
  max-width: 1200px;
  width: 100%;
  grid-template-columns: 45% 55%;
  grid-template-rows: 1fr;
  grid-template-areas: "userInfo cards";

  .salutations {
    font-size: 24px;
    line-height: 31px;
    font-weight: 400;
  }
}

.dashboard {
  display: flex;
  overflow: visible;
  padding: 20px 0 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .content {
    box-sizing: border-box;
    padding: 0;
    padding-top: 36px;
    padding-bottom: 32px;

    .titleContent {
      color: $blur-white;
      margin-bottom: 40px;
    }

    .dashboardHeading {
      margin-bottom: 0.5rem;
      font-family: $circularStd;
    }

    .dashboardContent {
      color: var(--textColor, $lightest-grey);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 12px;
    }
  }

  .userInfo {
    grid-area: userInfo;
    color: #fff !important;
    flex: 0 1 45%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 30px 31px 27px 58px 1fr;
    grid-template-areas:
      "."
      "salutation"
      "customerSince"
      "."
      "customerHistory";

    .salutations {
      grid-area: salutation;
      font-size: 24px;
      line-height: 31px;
      font-weight: 400;
      color: #ffffff;
    }

    .customerSince {
      grid-area: customerSince;
      font-weight: 300;
      font-size: 1.125rem;
      color: #ffffff;
    }

    .customerHistory {
      grid-area: customerHistory;
    }
  }

  .cards {
    display: flex;
    gap: 24px;
    grid-area: cards;
    max-height: 228px;
    height: 224px;
    flex: 0 1 55%;
    color: $black-color;
  }
}

@include media-breakpoint-up(xl) {
  .content {
    width: 100%;
    max-width: 1600px;
  }

  .dashboard {
    padding-top: 28px;
    padding-bottom: 28px;
    padding-right: 8%;
    padding-left: 8%;
  }

  .homePageUserInfo {
    margin-bottom: 56px;
  }
}

@include media-breakpoint-up(md) {
  .dashboardHeading {
    font-weight: 500;
    line-height: 1.2;
  }
}

@include media-breakpoint-between(md, xl) {
  .content {
    width: 100%;
    max-width: 1600px;
    padding: 64px 40px 32px;
  }

  .homePageUserInfo {
    padding: {
      left: 40px;
      right: 40px;
    }

    grid-template-columns: 1fr;
    row-gap: 0;
    grid-template-rows: 20px 82px 1fr 20px;
    grid-template-areas:
      "."
      "userInfo"
      "cards"
      ".";

    .userInfo {
      flex: 1 1 100%;
      grid-template-columns: calc(50% - 12px) calc(50% - 12px);
      grid-template-rows: 28px 24px;
      column-gap: 24px;
      grid-template-areas:
        "salutation    customerHistory"
        "customerSince customerHistory";
    }
  }

  .dashboard {
    width: 100%;
    max-width: 1600px;
    padding-right: 8%;
    padding-left: 8%;

    .dashboardHeading {
      font-size: 2.875rem;
    }
  }
}

@include media-breakpoint-down(md) {
  .homePageUserInfo {
    padding: {
      left: 24px;
      right: 24px;
    }

    grid-template-columns: 1fr;
    row-gap: 0;
    grid-template-rows: 24px 134px 20px 1fr 24px;
    grid-template-areas:
      "."
      "userInfo"
      "."
      "cards"
      ".";

    .userInfo {
      grid-template-columns: 100%;
      grid-template-rows: 28px 20px 20px 47px;
      column-gap: 0;
      grid-template-areas:
        "salutation"
        "customerSince"
        "."
        "customerHistory";
    }
  }

  .dashboard {
    @media (min-width: 701px) {
      max-width: 1600px;
      padding-right: 8%;
      padding-left: 8%;
    }
    @media (max-width: 700px) {
      max-width: 650px;
      padding-right: 5%;
      padding-left: 5%;
    }

    .dashboardHeading {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 45px;
    }
  }
}

.loading {
  background-color: $blur-white;
  opacity: 0.1;
}

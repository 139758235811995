@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

@mixin topSellingCategoriesSprite() {
  background-image: url("/media/dental/common/homepage/sprite-dental-categories.jpg");

  &#top-category- {
    &cosmetic-dentistry {
      background-position: 0px 0px;
    }

    &impression-materials {
      background-position: 0px -100px;
    }

    &cements-and-liners {
      background-position: 0px -200px;
    }

    &infection-control-clinical {
      background-position: 0px -300px;
    }

    &anesthetic-products {
      background-position: 0px -400px;
    }

    &preventatives {
      background-position: 0px -500px;
    }

    &infection-control-personal {
      background-position: 0px -600px;
    }

    &x-ray-products {
      background-position: 0px -900px;
    }

    &disposables {
      background-position: 0px -1100px;
    }

    &surgical-supplies {
      background-image: url(/media/shared/common/mp/surgical-specialties/look/small/look-559B.png);
      background-position: 0 0;
      background-size: 100px 100px;
    }

    &evacuation-products {
      background-image: url(/media/shared/common/mp/plasdent/plasdent/small/plasdent-evacuator-tips-assorted-8008c.png);
      background-position: 0 0;
      background-size: 100px 100px;
    }

    &core-materials {
      background-image: url(/media/shared/common/mp/gc-corporation/miracle-mix/small/core-build-up-kit-000239.png);
      background-position: 0 0;
      background-size: 100px 100px;
    }
  }
}

.topCategorySlider {
  &.mobile :global(.swiper-wrapper) {
    height: auto !important; // As of writing, swiper tries to set the height to 0, this forces it not to do that.
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

#topSellingCategories {
  max-width: 1420px;
  width: 100%;
  margin: auto;

  .category-name:hover {
    color: $vertical-color-50 !important;
    text-decoration: underline;
  }

  @include media-breakpoint-up(md) {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sectionHeader {
    text-align: center;
    font-weight: 400;
    padding-bottom: 20px;

    @include media-breakpoint-down(md) {
      text-align: center;
      font-size: 20px;
      padding-top: 16px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 20px;
    }

    font-size: 24px;
    line-height: 28.8px;
    color: $black-color;
    position: relative;

    padding: {
      left: 60px;
      right: 60px;
    }

    @include media-breakpoint-down(sm) {
      font-weight: 500;

      padding: {
        left: 10px;
        right: 10px;
      }
    }
  }

  * {
    outline: none !important;
  }

  .font-weight-bold {
    font-weight: $font-weight-bold;
  }

  .text-dark {
    color: $darker-black;
  }

  .topCategoryCard {
    display: flex;
    flex-direction: column;

    * {
      display: block;
      text-align: center;
    }

    a:not(.top-selling-category-image) {
      padding-bottom: 2px;
    }

    .top-selling-category-image {
      width: 100px;
      height: 100px;
      margin: auto;
      margin-bottom: 10px;
      background-size: 100px;
      background-repeat: no-repeat;

      background-position: -1000px -1000px;

      @include topSellingCategoriesSprite();
    }

    @include media-breakpoint-down(sm) {
      .sub-category-link {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem;
  }
}

.swiperArrow {
  position: relative;
  height: 40px;
  width: 60px;
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: calc(50% - 20px);
  z-index: 2;

  &:before {
    content: "";
    display: block;
    border-top: 2px solid #989898;
    border-left: 2px solid #989898;
    width: 18px;
    height: 18px;
  }

  &:global(.swiper-button-disabled) {
    opacity: 0.5;
    cursor: default;
  }

  &.prev {
    left: 0;

    &:before {
      transform: rotate(-45deg);
    }
  }

  &.next {
    right: 0;

    &:before {
      transform: rotate(135deg);
    }
  }
}

.swiper {
  margin-left: 0px;
}

.swiper-arrow {
  cursor: pointer;
}

.swiper-arrow-style {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  min-width: 15px;

  &:before {
    content: "";
    display: block;
    border-top: 2px solid $vertical-color-85;
    border-left: 2px solid $vertical-color-85;
    width: 10px;
    height: 10px;
  }
}

.swiper-arrow-not-primary {
  height: 36px;
}

.swiper-arrow-prev {
  margin-right: 0.5em;
  transition: 0.5s ease-in-out;

  &:before {
    transform: rotate(-45deg);
  }
}

.swiper-arrow-next {
  margin-left: 0.5em;
  position: relative;
  transition: 0.5s ease-in-out;

  &:before {
    transform: rotate(135deg);
  }
}

.swiper-button-disabled {
  opacity: 0;
  transition: 0.5s ease-in-out;
  cursor: unset;
}

#header-secondary-swiper {
  .swiper-slide {
    width: auto;
  }

  .swiper-button-disabled {
    opacity: 0;
    transition: 0.5s ease-in-out;
    cursor: unset;
  }

  .swiper-arrow {
    cursor: pointer;
  }

  .swiper-arrow-style {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    min-width: 15px;

    &:before {
      content: "";
      display: block;
      border-top: 2px solid $vertical-color-85;
      border-left: 2px solid $vertical-color-85;
      width: 10px;
      height: 10px;
    }
  }

  .swiper-arrow-not-primary {
    height: 36px;
  }

  .swiper-arrow-prev {
    margin-right: 0.5em;
    transition: 0.5s ease-in-out;

    &:before {
      transform: rotate(-45deg);
    }
  }

  .swiper-arrow-next {
    margin-left: 0.5em;
    position: relative;
    transition: 0.5s ease-in-out;

    &:before {
      transform: rotate(135deg);
    }
  }
}

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.topSortBanner {
  $max-height-desktop: 400px;
  $max-height-mobile: 650px;
  $banner-padding: 50px;

  .topSortCarousel {
    width: 100%;
    height: 100%;
  }

  .bannerImage {
    width: 100%;
    max-width: 1600px;
    height: auto;
    object-fit: cover;
  }

  .bannerItem {
    padding-bottom: 50px;
  }

  button {
    background-color: #c6cfde;
    width: 10px !important;
    height: 10px !important;
    margin-right: 7px;
    margin-left: 7px;
    border-radius: 50%;
    opacity: 1;
    transition: background-color 0.6s ease;

    &.active {
      background-color: $primary;
    }
  }

  @media (min-width: 701px) {
    max-height: $max-height-desktop + $banner-padding;
    max-width: 1600px;

    .bannerImage {
      max-height: $max-height-desktop;
      max-width: 1600px;
      aspect-ratio: 16 / 4;
    }
  }

  @media (max-width: 700px) {
    max-height: $max-height-mobile + $banner-padding;
    max-width: 650px;

    .bannerImage {
      max-height: $max-height-mobile;
      max-width: 650px;
      aspect-ratio: 1 / 1;
    }
  }
}

$roboto: "Roboto", sans-serif;
$circularStd: "Circular Std", "Roboto", sans-serif;

@mixin robotoBlack {
  color: $black-color;
  font-family: $roboto;
}

$true-black: #000000;
$black-color: #353c43;
$darker-black: #2c2c2c;
$darkest-grey: #565656;
$darker-grey: #8c8c8c;
$medium-grey: #888888;
$dark-grey: #dcdcdc;
$lightest-grey: #f8f8f8;
$custom-white: #fcfcfc;
$blur-white: #ffffff;
$light-grey: #e4e4e4;
$early_grey: #a1a1a1;
$grey-shadow-color: #bcbcbc; // Equivalent to RGBA(188,188,188,0.5)
$grey-toggle-color: #e0e0e0; // Equivalent to RGBA(224,224,224,1)

$savings-color: #00cc99;
$action-color: #fd784f;
$ratings-color: #fda803;
$error-color: #fd4040;
$error-background-color: rgba(253, 64, 64, 0.1);

$verticalMediaURLFolder: "dental";
$vertical-color-90: #f3f7fe;
$vertical-color-85: #e8f0fc;
$vertical-color-70: #c7cfe0;
$vertical-color-60: #9bbbf3;
$vertical-color-50: #4385f6;
$vertical-color-42: #276ad5;
$vertical-color-40: #237ad5;
$vertical-color-20: #1a5bc3;
$vertical-header-sprite: "/media/dental/common/icons/sprite-header-net32-large.png";
$vertical-icons: "/media/dental/common/icons/sprite-header-net32-large.svg";
$vertical-brand-sprite: "/media/dental/common/homepage/sprite-dental-brands.jpg";
$vertical-checkbox: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMTQgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU5LjEgKDg2MTQ0KSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5pbnNpZGUtY2hlY2s8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iS2xhdml5byIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlnb24gaWQ9Imluc2lkZS1jaGVjayIgZmlsbD0iI0ZGRkZGRiIgcG9pbnRzPSI1IDExIDAgNiAxLjQxIDQuNTkgNSA4LjE3IDEyLjU5IDAuNTggMTQgMiI+PC9wb2x5Z29uPgogICAgPC9nPgo8L3N2Zz4=");
$vertical-star-image-path: "/media/dental/common/reviews/star-vendor-net32.png";
$vertical-guarantee-image-path: "/media/dental/common/homepage/gaurantees-sprite-net32.png";
$vertical-purchase-history-icon: "/media/dental/common/icons/icon-purchase-history.svg";
$reorder-shopping-cart-bg: #c8cfe1;
$footer-horizontal-divider-color: #80adf5;
$homepage-weekly-specials-savings-color: #44d6ad;
$searchSpringLinkColor: #2a5a88;
$searchViewSprite: url(/media/dental/common/icons/icon-result-layout-sprite.svg);

@mixin fullColorLogo($viewPort) {
  background-image: url(/media/dental/common/icons/icons-sprite-2.svg);
  background-repeat: no-repeat;
  @if $viewPort == "phone" {
    height: 35px;
    width: 96px;
    background-position: 0 -41px;
    background-size: 96px 72px;
  } @else {
    height: 50px;
    width: 150px;
    background-position: 0 -70px;
    background-size: 150px 112px;
  }
}

@mixin whiteLogo($viewPort) {
  background-image: url($vertical-icons);
  background-repeat: no-repeat;
  @if $viewPort == "sm" {
    height: 35px;
    width: 96px;
    background-position: 0 -41px;
    background-size: 96px 72px;
  } @else {
    padding: 0px;
    width: 133px;
    height: 38px;
    background-size: 184px 182px;
    background-position: 0px 0px;
    background-repeat: no-repeat;
  }
}

@mixin backgroundIconPrinterSVG {
  background-image: url(/media/dental/common/icons/icon-printer.svg);
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
@mixin brandSprite() {
  background-image: url($vertical-brand-sprite);
  background-size: 101px 330px;
  background-repeat: no-repeat;
  background-position-x: -1px;
  background-position-y: 1000px; // this is to make it look blank if it's wrong.
  &#brand- {
    &mydent {
      background-image: url(/media/dental/common/homepage/brands/DEFEND.svg);
      background-position: center center;
      background-size: 70px;
      background-repeat: no-repeat;
    }
    &metrex {
      background-image: url(/media/dental/common/homepage/brands/metrex-logo.svg);
      background-position: center center;
      background-size: 70px;
      background-repeat: no-repeat;
    }
    &carestream {
      background-position-y: -84px;
    }
    &kerr {
      background-position-y: -124px;
    }
    &dmg-america-zenith {
      background-position-y: -164px;
    }
    &sultan-healthcare {
      background-position: 1px -245px;
    }
    &septodont {
      background-position-y: -289px;
    }
    &3m {
      background-image: url(/media/dental/common/homepage/brands/3M_logo_only.svg);
      background-position: center center;
      background-size: 45px;
      background-repeat: no-repeat;
    }
    &premier-dental {
      background-image: url(/media/dental/common/homepage/brands/Premier_Logo_R_RGB.png);
      background-position: center center;
      background-size: 70px;
      background-repeat: no-repeat;
    }
  }
}

@mixin getResizedBackgroundImageURL($width, $height, $uri) {
  background-image: url("https://www.net32.com/cdn-cgi/image/dpr=2,width=#{$width},height=#{$height}#{$uri}");
}

$primary: $vertical-color-50;
$secondary: $action-color;
$danger: $error-color;
$light: $light-grey;
$dark: $darker-black;
// Contrast colors
$color-contrast-dark: $black-color;
$color-contrast-light: #ffffff;
$min-contrast-ratio: 3;

// Font Related
$font-size-base: 0.875rem; // 14px
$font-size-lg: 1.125rem; // 18px
$font-size-sm: 0.75rem; // 12px
$h1-font-size: 2.875rem; // 46px
$h2-font-size: 2.875rem; // 46px
$h3-font-size: 2.25rem; // 36px
$h4-font-size: 2.25rem; // 36px
$h5-font-size: 1.5rem; // 24px
$h6-font-size: 1.5rem; // 24px
$headings-font-family: $circularStd;
$font-family-sans-serif: $roboto;
$font-family-monospace: $roboto;
$font-family-base: $roboto;
$font-family-code: $roboto;
$font-weight-lighter: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;
$lead-font-size: $font-size-lg;
$lead-font-weight: $font-weight-bold;
$small-font-size: $font-size-sm;

// Links
$link-decoration: none;

// Forms
$custom-control-indicator-active-bg: $vertical-color-50;
$custom-control-indicator-active-border-color: $vertical-color-50;
$custom-control-indicator-active-box-shadow: inset 1px 1px 0 1px rgba(228, 147, 147, 0.9);
$custom-control-indicator-focus-border-color: #adb5bd;
$input-focus-box-shadow: none;
$form-check-input-focus-box-shadow: none;
$input-height: 2.625rem; // 42px
$input-border-radius: 2px;
$input-padding-y: 0.6875rem; // 11px
$input-padding-x: 0.6875rem; // 11px
$input-color: $black-color;
$input-border-color: $vertical-color-70;
$input-border-width: 1px;
$form-label-font-size: 0.75rem;
// $form-label-font-style: null !default;
// $form-label-font-weight: null !default;
// $form-label-color: null !default;
// $form-feedback-valid-color: $success !default;
$form-validation-states: (
  "invalid": (
    "color": $danger,
    "border-color": $danger,
    "icon": "none",
    "tooltip-color": #fff,
    "tooltip-bg-color": $danger,
    // "focus-box-shadow": 0 0 $input-btn-focus-blur $input-focus-width rgba($danger-rgb), $input-btn-focus-color-opacity),
  ),
);

// Grid
$grid-gutter-width: 24px;

// Button Related
$btn-padding-y: 11px;
$btn-padding-x: 8px;
$border-radius: 2px;
$border-radius-lg: 2px;
$border-radius-sm: 2px;

// Transition related
$transition-method: ease-in-out;
$transition-time: 0.35s;
$btn-transition:
  color $transition-time ease-in-out,
  background-color $transition-time ease-in-out,
  border-color $transition-time ease-in-out,
  box-shadow $transition-time ease-in-out;
// List Groups
$list-group-hover-bg: #f0f2f5; // Same color as gray-200
// Modal Related
$modal-header-border-width: 0;
$modal-inner-padding: 0.75rem;

// Popovers
$popover-border-color: $light-grey;

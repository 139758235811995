@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

$maxWidth: 1300px;

#testimonials {
  background-color: $vertical-color-90;
  * {
    text-align: center;
  }
  height: fit-content;
  .carousel {
    height: 260px;

    .carousel-indicators {
      z-index: 2;

      button {
        background-color: #c6cfde;
        width: 10px;
        height: 10px;
        margin-right: 7px;
        margin-left: 7px;
        border-radius: 50%;
        opacity: 1;
        transition: background-color 0.6s ease;

        &.active {
          background-color: rgb(69, 135, 240);
        }
      }
    }

    .carousel-item {
      padding-top: 50px;

      > div {
        padding: {
          right: 20px;
          left: 20px;
        }

        * {
          max-width: $maxWidth;
          margin: auto;
        }

        p {
          font-size: 22px;
          line-height: 1.7em;
          font-weight: 300;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          white-space: pre-wrap;
        }

        strong {
          font-size: 16px;
          line-height: 1.7em;
        }
      }
    }
  }

  .read-more-container {
    height: 100px;
    .read-more-button {
      color: theme-color("primary");
      background-color: white;
      font-size: 14px;
      line-height: 20px;
      padding: 7px 24px;
      height: 36px;
      border-radius: 18px;
      text-decoration: none !important;
      &:hover,
      &:active {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08) !important;
      }
    }
  }
  @include media-breakpoint-between(sm, lg) {
    .carousel {
      height: 305px;
    }
    .read-more-container {
      height: 65px;
    }
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem;
    .carousel {
      height: 315px;
      .carousel-item {
        padding-top: 0;
        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 260px;
          padding: 0;
          p {
            padding-bottom: 0;
          }
        }
      }
    }
    .read-more-container {
      height: 36px;
    }
  }
}

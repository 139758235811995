@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.specialities {
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 80px;
  max-width: 1300px;
  //width: calc(100% - 120px);
  margin: auto;

  padding: {
    left: 2rem;
    right: 2rem;
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .title {
    font-family: $circularStd;
    color: $black-color;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
    white-space: nowrap;
  }

  .subtitle {
    @include robotoBlack();
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    white-space: nowrap;
  }

  .specialitiesList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 600px;
    max-height: 106px;
    gap: 16px 8px;
    overflow: hidden;

    :global(.btn-pillbox) {
      padding: {
        top: 7px;
        bottom: 7px;
      }

      margin: 0 !important;
      font-weight: 500;
    }
  }

  @include media-breakpoint-up(lg) {
    .specialitiesText {
      padding-right: 5rem;
    }
  }

  @include media-breakpoint-only(md) {
    margin-bottom: 1.75rem;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 1rem;
  }

  @media (min-width: 1240px) and (max-width: 1326px), (min-width: 935px) and (max-width: 1150px) {
    .specialitiesText {
      padding-right: 3rem;

      .title {
        font-size: 26px;
      }

      .subtitle {
        font-size: 16px;
      }
    }
  }

  @media (min-width: 1140px) and (max-width: 1240px) {
    .specialitiesList {
      flex-basis: 420px;

      :global(#public-health),
      :global(#pedodontist) {
        display: none;
      }
    }
  }

  @media (min-width: 1030px) and (max-width: 1139.98px) {
    .specialitiesList {
      flex-basis: 400px;

      :global(#public-health),
      :global(#pedodontist),
      :global(#prosthodontist) {
        display: none;
      }
    }
  }

  @media (min-width: 935px) and (max-width: 1029.98px) {
    .specialitiesList {
      flex-basis: 290px;

      :global(#public-health),
      :global(#pedodontist),
      :global(#prosthodontist),
      :global(#periodontist) {
        display: none;
      }
    }
  }

  @media (max-width: 934.98px) {
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;

    .text {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: auto;
      padding-right: 10px;
      padding-left: 10px;
      padding-bottom: 18px;

      * {
        text-align: center;
      }

      @media (max-width: 500px) {
        .title {
          font-size: 26px;
          white-space: normal;

          span {
            white-space: nowrap;
          }
        }

        .subtitle {
          font-size: 15px;
          white-space: normal;
        }
      }

      @media (max-width: 415px) {
        .subtitle {
          max-width: 230px;
          margin: auto;
        }
      }
    }

    .specialitiesList {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: auto;
      justify-content: center;
      max-width: 675px;

      @media (max-width: 650px) {
        max-width: 540px;

        :global(#public-health) {
          display: none;
        }
      }

      @media (max-width: 560px) {
        max-width: none;
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;

        :global(#pedodontist) {
          display: none;
        }
      }

      @media (max-width: 460px) {
        max-width: 450px;

        :global(#prosthodontist) {
          display: none;
        }
      }

      @media (max-width: 425px) {
        :global(#periodontist) {
          display: none;
        }
      }

      @media (max-width: 320px) {
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        max-height: 200px;

        :global(#periodontist) {
          display: none;
        }

        * {
          overflow: visible;
        }
      }
    }
  }
}

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

#core-values {
  display: flex;
  max-width: 1300px;
  margin: auto;

  @include media-breakpoint-down(lg) {
    padding: 1.75rem 2rem;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    padding-top: 25px;
    padding-bottom: 5px;
  }
  *:not(a) {
    color: $black-color;
  }
  > div {
    text-align: center;
    flex-grow: 1;
    flex-shrink: 1;

    .core-value-image {
      display: block;
      background-image: url($vertical-guarantee-image-path);
      height: 100px;
      width: 100%;
      background-size: 100px;
      background-repeat: no-repeat;
      + * {
        padding-top: 10px;
      }
    }
    @include media-breakpoint-up(lg) {
      flex-basis: 33%;
    }
    @include media-breakpoint-between(md, lg) {
      flex-basis: 50%;
    }
    @include media-breakpoint-down(sm) {
      padding-bottom: 20px;
      .light {
        display: none;
      }
      .core-value-image {
        background-size: 75px;
        height: 76px;
        + * {
          padding-top: 5px;
        }
      }
    }
  }
  #money-back-gaurantee {
    .core-value-image {
      background-position: center 0px;
    }
  }
  #center-core-value {
    .core-value-image {
      background-position: center -105px;
      @include media-breakpoint-down(sm) {
        background-position: center -79px;
      }
    }
  }
  #thousands-of-reviews {
    .core-value-image {
      background-position: center -209px;
      @include media-breakpoint-down(sm) {
        background-position: center -156px;
      }
    }
    @include media-breakpoint-between(md, lg) {
      display: none;
    }
  }
}

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.homePageSearchDashboard {
  z-index: 2;
}

#header-secondary-swiper {
  .swiper-button-disabled {
    opacity: 0;
    transition: 0.5s ease-in-out;
    cursor: unset;
  }
}

.searchContainer {
  padding: 0;
  width: 100%;
  max-width: 1600px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40px auto;
  gap: 12px;
  margin-bottom: 48px;
}

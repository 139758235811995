@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

$padding-for-arrow: 60px;
$maxWidth: 1300px;

@mixin sectionHeaderStyles {
  section {
    max-width: $maxWidth;
    width: calc(100% - #{$padding-for-arrow * 2});
    padding: {
      left: 2rem;
      right: 2rem;
      top: 1.75rem;
      bottom: 1.75rem;
    }
    margin: auto;

    &.containsRecommendations {
      max-width: #{$maxWidth + ($padding-for-arrow * 2)};
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      max-width: 100%;
      padding: {
        left: 0;
        right: 0;
        top: 0;
        bottom: 1rem;
      }
      &.containsRecommendations {
        max-width: 100%;
      }
    }
  }
  .sectionHeader {
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    color: $black-color;
    padding-bottom: 20px;
    text-align: center;
    position: relative;
    padding: {
      left: $padding-for-arrow;
      right: $padding-for-arrow;
    }

    .btn {
      position: absolute;
      right: $padding-for-arrow;
    }

    .wsCountdownMobileVersion {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      text-align: left;
      font-size: 18px;
      font-weight: 500;
      padding: {
        left: 10px;
        right: 10px;
      }
      .wsCountdownDesktopVersion {
        display: none;
      }
      .wsCountdownMobileVersion {
        display: inline;
      }
      .btn {
        right: 10px;
      }
    }
  }
}
